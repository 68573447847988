<template>
    <v-container id="login-view" tag="section">
        <p class="Title">{{ $tr("Alamal Group") }}</p>
        <p class="title">{{ $tr("Task Managment App") }}</p>
        <v-row justify="center">
            <v-col cols="12">
                <v-slide-y-transition appear>
                    <v-card
                        max-width="550"
                        class="mx-auto rounded-lg"
                        showHeading
                    >
                        <v-card-text class="text-center">
                            <v-text-field
                                outlinded
                                v-model="user.username"
                                color="theme"
                                placeholder="Username..."
                                prepend-icon="mdi-account-outline"
                            />

                            <v-text-field
                                v-model="user.password"
                                class="mb-4"
                                color="theme"
                                placeholder="Password..."
                                prepend-icon="mdi-lock-outline"
                                type="password"
                            />

                            <v-btn
                                color="theme"
                                rounded
                                :disabled="loading"
                                :loading="loading"
                                text
                                large
                                @click="login"
                            >
                                Login
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-slide-y-transition>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
/* eslint-disable */
import { sync } from "vuex-pathify";
import Api from "@/api/api/index.js";
export default {
    name: "LoginView",

    data: () => ({
        loading: false,
        user: {
            password: "",
            username: "",
        },
        socials: [
            {
                href: "#",
                icon: "mdi-facebook",
            },
            {
                href: "#",
                icon: "mdi-twitter",
            },
            {
                href: "#",
                icon: "mdi-github",
            },
        ],
    }),

    computed: {
        ...sync("user", {
            loading_cover: "visualization@loading_cover",
            userProfile: "user",
            token: "auth@token",
        }),
    },
    methods: {
        async login() {
            console.log(this, this && this.user);
            this.loading = true;
            try {
                const result = (await Api.post("user/auth/login", this.user))
                    .data.result;
                this.userProfile = result.user;
                this.token = result.token;
                await this.$store.dispatch("user/update_localstorage");
                await this.$router.push(
                    this.$store.getters["user/default_route_for_user_role"]
                );
                this.loading = false;
            } catch (err) {
                console.log(err);
                this.loading = false;
                let notification = {
                    msg:
                        err.response?.data?.error?.msg ||
                        "Error, please try again later",
                    color: "error",
                };
                this.$store.dispatch("user/notify", notification);
            }
        },
    },
};
</script>
<style scoped>
.Title {
    margin-top: 10% !important;
    margin-bottom: 0% !important;
    font-family: "Poppins-bold";
    font-style: normal;
    font-weight: bolder !important;
    font-size: 32px;
    line-height: 120%;
    /* or 38px */

    align-items: center;
    text-align: center;

    /* Brand Color */

    color: #006ee9;
}
.title {
    font-family: "Poppins" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 140%;
    /* or 22px */

    align-items: center;
    text-align: center;

    color: #9a9a9a;
}
</style>
